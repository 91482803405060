@font-face {
    font-family: "FestivoLCSketch2";
    src: url('/public/fonts/FestivoLCSketch2.woff2') format('woff2'), url('/public/fonts/FestivoLCSketch2.woff') format('woff');
}

@font-face {
    font-family: "FestivoLCWood";
    src: url('/public/fonts/FestivoLCWood.woff2') format('woff2'), url('/public/fonts/FestivoLCWood.woff') format('woff');
}

@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);